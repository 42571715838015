import HttpRequest  from "./http_request";

class UserProvider extends HttpRequest {
    async getTakeList(userRefId, roundId) {
        return this.get(`users/take-list/${userRefId}/${roundId}`);
    }

    async getAllWarning() {
        return this.get('/users/warning')
    }

    async addWarning(data) {
        return this.create('/users/warning/add', data)
    }

    async editWarning(id, data) {
        return this.update(`/users/warning/${id}/edit`, data)
    }

    async getRankingData() {
        return this.get('/users/member/ranking')
    }
}

export default UserProvider;