<template>
  <div>
    <bread-cum v-bind:breadcrumbItems="['จัดการสมาชิก', 'warning']" />
    <div class="card border-primary">
      <h5 class="card-header border-primary bg-primary text-white">
        Warning Management
      </h5>
      <div class="card-body">
        <button class="btn btn-success text-white mb-2" @click="openFormCreate">
          เพิ่ม Warning
        </button>
        <CDataTable
          :items="items"
          :fields="fields"
          :items-per-page-select="false"
          :items-per-page="20"
          :no-items-view="{
            noResults: 'ไม่พบข้อมูล',
            noItems: 'ไม่พบข้อมูล',
          }"
          :table-filter="true"
          border
          striped
          pagination
          dark
          hover
          size="sm"
          add-table-classes="w-100 text-wrap text-center"
        >
          <template #type="{ item }">
            <td>
              <span class="badge badge-success">{{
                getTypeName(item.type)
              }}</span>
            </td>
          </template>
          <template #status="{ item }">
            <td>
              <span
                class="badge"
                :class="{
                  'badge-success': item.status == 'ACTIVE',
                  'badge-danger': item.status == 'INACTIVE',
                }"
                >{{ getStatusName(item.status) }}</span
              >
            </td>
          </template>
          <template #edit="{ item }">
            <td>
              <button class="btn btn-primary" @click="openFormEdit(item)">
                <i class="fa fa-edit"></i>
              </button>
            </td>
          </template>
        </CDataTable>
      </div>
    </div>
    <b-modal
      id="modal-form"
      size="xl"
      body-bg-variant="dark"
      body-text-variant="light"
      header-bg-variant="dark"
      header-text-variant="light"
      footer-bg-variant="dark"
      footer-text-variant="light"
      :title="(form.id == null ? 'เพิ่ม' : 'แก้ไข') + 'ค่ายเกม'"
      cancel-title="ยกเลิก"
      ok-title="ตกลง"
      hide-footer
    >
      <ValidationObserver
        ref="observer"
        tag="form"
        @submit.prevent="submitForm"
        novalidate
      >
        <div class="row">
          <div class="col-12">
            <!-- <div class="form-group row" v-if="form.id">
              <span class="col-12"> #ID: </span>
              <span class="col-12">{{ form.id }}</span>
            </div> -->
            <div class="form-group row">
              <label for="form-warning-text" class="col-12">
                Warning Text:
              </label>
              <div class="col-12">
                <ValidationProvider rules="required" v-slot="v">
                  <input
                    id="form-warning-text"
                    type="text"
                    class="form-control mr-1"
                    v-model="form.text"
                    autocomplete="off"
                  />
                  <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="form-group row">
              <label for="form-warning-reason" class="col-12">
                เหตุผลการ Warning:
              </label>
              <div class="col-12">
                <ValidationProvider rules="required" v-slot="v">
                  <input
                    id="form-warning-reason"
                    type="text"
                    class="form-control mr-1"
                    v-model="form.reason"
                    autocomplete="off"
                  />
                  <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12" for="form-warning-type">ประเภท:</label>
              <div class="col-12">
                <ValidationProvider rules="required" v-slot="v">
                  <select
                    v-model="form.type"
                    class="form-control"
                    id="form-warning-type"
                  >
                    <option
                      v-for="type in types"
                      :key="type.value"
                      :value="type.value"
                    >
                      {{ type.label }}
                    </option>
                  </select>
                  <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12" for="form-status">สถานะ:</label>
              <div class="col-12">
                <ValidationProvider rules="required" v-slot="v">
                  <select
                    v-model="form.status"
                    class="form-control"
                    id="form-status"
                  >
                    <option
                      v-for="status in statuses"
                      :key="status.value"
                      :value="status.value"
                    >
                      {{ status.label }}
                    </option>
                  </select>
                  <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <hr />
            <div class="form-group row">
              <div class="col-12 text-right">
                <button class="btn btn-danger" @click="closeForm" type="button">
                  ยกเลิก
                </button>
                <button class="btn btn-primary ml-3" type="submit">
                  {{ form.id ? "บันทึก" : "เพิ่ม" }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </ValidationObserver>
    </b-modal>
  </div>
</template>

<style>
</style>

<script>
import UserProvider from "@/resources/user_provider"
const userService = new UserProvider()

export default {
  data() {
    return {
      fields: [
        {
          key: "text",
          label: "Warning Text",
        },
        {
          key: "type",
          label: "ประเภท",
          filter: false,
        },
        {
          key: "reason",
          label: "Warning Reason",
        },
        {
          key: "status",
          label: "สถานะ",
          filter: false,
        },
        {
          key: "updatedByName",
          label: "ผู้ทำรายการ",
        },
        {
          key: "updatedAt",
          label: "เวลาทำรายการ",
        },
        {
          key: "edit",
          label: "แก้ไข",
        },
      ],
      form: {
        id: null,
        text: "",
        type: "PASSWORD",
        reason: "",
        status: "ACTIVE",
      },
      items: [],
      types: [
        {
          value: "PHONE_NO",
          label: "เบอร์โทร",
        },
        {
          value: "LASTNAME",
          label: "นามสกุล",
        },
        {
          value: "PASSWORD",
          label: "รหัสผ่าน",
        },
      ],
      statuses: [
        {
          value: "ACTIVE",
          label: "เปิด Warning",
        },
        {
          value: "INACTIVE",
          label: "ปิด Warning",
        },
      ],
      isLoading: false,
    }
  },
  computed: {
    typeMap() {
      const map = {}
      this.types.forEach((ele) => {
        map[ele.value] = ele
      })
      return map
    },
    statusMap() {
      const map = {}
      this.statuses.forEach((ele) => {
        map[ele.value] = ele
      })
      return map
    },
  },
  mounted() {
    this.getAllWarning()
  },
  methods: {
    clearForm() {
      this.form.id = null
      this.form.text = ""
      this.form.type = "PASSWORD"
      this.form.reason = ""
      this.form.status = "ACTIVE"
    },
    openFormCreate() {
      this.clearForm()
      this.$bvModal.show("modal-form")
    },
    openFormEdit(item) {
      this.clearForm()
      this.form.id = item.id
      this.form.text = item.text
      this.form.type = item.type
      this.form.reason = item.reason
      this.form.status = item.status
      this.$bvModal.show("modal-form")
    },
    closeForm() {
      this.$bvModal.hide("modal-form")
    },
    async submitForm() {
      let observer = this.$refs.observer
      let isValid = await observer.validate()
      if (!isValid) {
        this.focusError(observer)
        return
      }

      this.swal.processing()
      const res = !this.form.id
        ? await userService.addWarning(this.form)
        : await userService.editWarning(this.form.id, this.form)
      if (res.error) {
        this.swal.swalError(res.error.message)
      } else {
        this.swal.swalSuccess(
          !this.form.id ? "เพิ่ม warning สำเร็จ" : "แก้ไข warning สำเร็จ",
          () => {
            this.getAllWarning()
            this.closeForm()
          }
        )
      }
    },
    async getAllWarning() {
      this.isLoading = true
      const res = await userService.getAllWarning()
      this.isLoading = false
      if (res.error) {
        this.swal.swalError(res.error.message)
        return
      }
      res.result.list?.forEach((item) => {
        item.createdAt = this.$date(item.createdAt).format(
          "DD/MM/YYYY HH:mm:ss"
        )
        item.updatedAt = this.$date(item.updatedAt).format(
          "DD/MM/YYYY HH:mm:ss"
        )
      })

      this.items = res.result.list
    },
    getTypeName(type) {
      return this.typeMap[type]?.label
    },
    getStatusName(status) {
      return this.statusMap[status]?.label
    },
  },
}
</script>